import { useSnackbar } from "notistack"
import { useMemo } from "react"

type MessageHandler = (message: string) => void

export const useSnacks = (): {
  showError: MessageHandler
  showSuccess: MessageHandler
  showWarning: MessageHandler
  showInfo: MessageHandler
} => {
  const { enqueueSnackbar } = useSnackbar()
  return useMemo(
    () => ({
      showError: (message: string) =>
        message && enqueueSnackbar(message, { variant: "error" }),
      showSuccess: (message: string) =>
        message && enqueueSnackbar(message, { variant: "success" }),
      showWarning: (message: string) =>
        message && enqueueSnackbar(message, { variant: "warning" }),
      showInfo: (message: string) =>
        message && enqueueSnackbar(message, { variant: "info" }),
    }),
    [enqueueSnackbar],
  )
}

export default useSnacks
