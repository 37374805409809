import { CacheProvider } from "@emotion/react"
import { ThemeProvider } from "@mui/material/styles"
import { IdProvider } from "@radix-ui/react-id"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/next"
import { MyClassesProvider } from "flex/context/my-classes-context"
import { NextIntlProvider } from "next-intl"
import NextApp from "next/app"
import { SnackbarProvider } from "notistack"
import PropTypes from "prop-types"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { getMessageFallback, getTranslations, onIntlError } from "@/lang"
import { createEmotionCache } from "@/pages/_document"
import { theme } from "@/styles/admin-theme"
import { ServerSideAuth } from "~/auth"
import GoogleTagManager from "~/components/google-tag-manager"
import { AuthProvider } from "~/context/auth"
import { RedirectProvider } from "~/context/redirect"
import { ViewportProvider } from "~/context/viewport"
import { YupProvider } from "~/context/yup"
import { ServiceClientError } from "~/errors/service-client-error"
import { PreferencesProvider } from "~/hooks/use-preferences"
import { isEmpty, withWindow } from "~/util"
import logger from "~/util/logger"
import { genPageViewTracker } from "~/util/tracking"
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"
import "@algolia/autocomplete-theme-classic/dist/theme.css"
import "instantsearch.css/themes/satellite.css"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

withWindow(window => {
  if (
    process.env.NODE_ENV === "production" ||
    process.env.NEXT_PUBLIC_AXE_DISABLED === "true"
  )
    return
  /* eslint-disable global-require */
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const ReactDOM = require("react-dom")
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require("@axe-core/react")
  /* eslint-enable global-require */
  axe(React, ReactDOM, 1000)
})

if (isEmpty(process.env.NEXT_PUBLIC_STRIPE_API_KEY)) {
  throw new ServiceClientError("Stripe API Key environment variable is not set")
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

export default function App({
  Component,
  emotionCache = clientSideEmotionCache,
  router,
  pageProps,
  translations,
  authData,
  preferences,
  referer,
  err,
  locale,
}) {
  /*
   * Why memoize authData?
   * - authData is only guaranteed to be valid on a server-side render, so we always use the last
   *   available server-side rendered value for authData. On subsequent server-side renders,
   *   authData will be updated appropriately
   * Why ssa = ServerSideAuth.deserialize(...) ?
   * - when performing client-side routing, the props passed to App seem to undergo a
   *   JSON serialization, so class instances (and their internal states) are not maintained;
   *   thus we always create a new ServerSideAuth object to guarantee one is available.
   * Whey memoize referer?
   * - referer is only used for redirect external to the application, which will always cause a
   *   server-side render, and thus we only need to set this value once per App render*/
  const queryClient = new QueryClient()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ssa = ServerSideAuth.deserialize(React.useMemo(() => authData, []))
  const _referer = React.useMemo(() => referer, [referer])
  React.useEffect(() => {
    // trigger when App is mounted
    const userId = ssa.userData?.id
    if (userId) {
      genPageViewTracker(userId, "pageViewTracker.initialLoad")()
    }
  }, [ssa.userData?.id])

  return (
    <>
      <CacheProvider value={emotionCache}>
        <GoogleTagManager>
          <QueryClientProvider client={queryClient}>
            <NextIntlProvider
              messages={translations}
              onError={onIntlError}
              getMessageFallback={getMessageFallback}>
              <YupProvider
                translationSet={translations?.yupLocalization}
                locale={locale}>
                <ThemeProvider theme={theme}>
                  <AuthProvider ssa={ssa} router={router}>
                    <IdProvider>
                      <RedirectProvider router={router} referer={_referer}>
                        <ViewportProvider>
                          <DndProvider backend={HTML5Backend}>
                            <PreferencesProvider
                              serializedPreferences={preferences}>
                              <SnackbarProvider>
                                <MyClassesProvider {...pageProps}>
                                  <Component {...pageProps} err={err} />
                                  <SpeedInsights />
                                </MyClassesProvider>
                              </SnackbarProvider>
                            </PreferencesProvider>
                          </DndProvider>
                        </ViewportProvider>
                      </RedirectProvider>
                    </IdProvider>
                  </AuthProvider>
                </ThemeProvider>
              </YupProvider>
            </NextIntlProvider>
          </QueryClientProvider>
        </GoogleTagManager>
      </CacheProvider>
      <Analytics />
    </>
  )
}

App.getInitialProps = async context => {
  const { router } = context
  const { locale, defaultLocale } = router
  const referer = context.ctx?.req?.headers?.referer

  // falling back to english prevents some confusing error screens during development
  const translations = await getTranslations(locale || defaultLocale || "en")

  const appProps = await NextApp.getInitialProps(context)

  /*
   * We can only provide authData if this is a server-side render; for client-side renders we will
   * have to rely on memoization. */
  const authData = await (async isServerSide => {
    if (!isServerSide) return null
    const ssa = new ServerSideAuth(context.ctx?.req.cookies["aoeu-session"])
    // forced attempt at loading
    const err = await ssa.load(true, false)
    if (err) logger.error(err)
    // In case of error, the application will be handed "unauthenticated" state
    return ssa.serialize()
  })(!!context.ctx?.req)

  return {
    ...appProps,
    locale,
    translations,
    authData,
    preferences: context.ctx?.req?.cookies?.preferences ?? "",
    referer,
  }
}

App.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  emotionCache: PropTypes.object,
  router: PropTypes.object,
  pageProps: PropTypes.object,
  locale: PropTypes.string,
  translations: PropTypes.object,
  authData: PropTypes.object,
  preferences: PropTypes.string,
  referer: PropTypes.string,
  err: PropTypes.object,
}
